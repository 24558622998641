// Fixes object fit incompatibility in internet explorer
if(!Modernizr.objectfit) {
    var bannerImgContainer = $(".split-banner .img-container");

    bannerImgContainer.each(function(){
        var bannerImg = $(this).find("img");

        if(bannerImg) {
            var imgUrl = bannerImg.attr("src");

            $(this)
                .css("background-image", "url(" + imgUrl + ")")
                .addClass("img-container__compat")
                .addClass("bg-img");

            bannerImg.addClass("d-none");

            // If a focal point is set, convert object position inline CSS to background-position for IE11 support
            objFitStyles = heroImg.css("object-position");

            if(objFitStyles) {
                bannerImgContainer.css("background-position", objFitStyles);
            }
        }
    })
}