$(document).ready(e => {
    let parsleyForms = $('form[data-parsley-js-validate]');

    parsleyForms.each((index, element) => {
        let shouldValidate = $(element);

        shouldValidate.parsley({
            errorClass: 'field--has-errors',
            errorsWrapper: '<ul class="field__errors"></ul>'
        });
    });
});