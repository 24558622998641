// Fixes object fit incompatibility in internet explorer
if(!Modernizr.objectfit) {
    var heroImgContainer = $(".hero__bg-img-wrapper");
    var heroImg = $(".hero__bg-img-wrapper img");

    if(heroImg) {
        var imgUrl = heroImg.attr("src");
        // If a focal point is set, convert object position inline CSS to background-position for IE11 support
        var objFitStyles = heroImg.css("object-position");

        heroImgContainer
            .css("background-image", "url(" + imgUrl + ")")
            .addClass("bg-img");

        heroImg.addClass("d-none");

        if(objFitStyles) {
            heroImgContainer.css("background-position", objFitStyles);
        }
    }
}